body {
  background-color: #F0F0F0;
}

.App {
  text-align: center;
}

.header {

}

.main {
  text-align: center;
}

.footer {
  text-align: right;
  padding-right: 15px;
}

.hidden {
  display: none;
}

.card {
  border: 2px solid #303030;
  border-radius: 15px;
  width: 400px;
  word-wrap: break-word;
  padding: 10px;
  margin: 10px;
  float: left;
  font-style: bold;
  color: #FFF;
}

.m1 {
  background-color: #00c040e5
}

.m2 {
  background-color: #eeff004f;
  color: #303030;
}

.m3 {
  background-color: #ff8819
}

.m4 {
  background-color: #fa3636
}

.title {
  font-size: larger;
}

.time {
  font-size: large;
}

a:link {
  text-decoration: none;
  color: #0C0C0C;
}

a:visited {
  text-decoration: none;
  color: #0C0C0C;
}

.selectYear{
  font-size: large;
  font-weight: bold;
}
